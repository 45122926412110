import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";

gsap.registerPlugin(ScrollTrigger);

/**
 * Base
 */

// Canvas
const canvas = document.querySelector(".webgl1");
let section = document.querySelector("#hp-about");

// Scene
const scene = new THREE.Scene();

/**
 * Loader
 */
const manager = new THREE.LoadingManager();
manager.onStart = function (url, itemsLoaded, itemsTotal) {
  console.log(
    "Started loading file: " +
      url +
      ".\nLoaded " +
      itemsLoaded +
      " of " +
      itemsTotal +
      " files."
  );
};

manager.onLoad = function () {
  console.log("Loading complete!");
};

manager.onProgress = function (url, itemsLoaded, itemsTotal) {
  console.log(
    "Loading file: " +
      url +
      ".\nLoaded " +
      itemsLoaded +
      " of " +
      itemsTotal +
      " files."
  );
};

manager.onError = function (url) {
  console.log("There was an error loading " + url);
};

/**
 * Models
 */

const gltfLoader = new GLTFLoader(manager);

let flowerMixers = [];
let flowers = [];
let mixer = null;
let flower1 = null;
let flower2 = null;
let flower3 = null;
let flower4 = null;
let action = null;

gltfLoader.load(
  "https://cdn.jsdelivr.net/gh/theNkennaAmadi/emil-lily-final@main/dist/models/emil-lily.glb",
  (gltf) => {
    flower1 = gltf.scene.children[0];
    flower1.scale.set(0.25, 0.25, 0.25);
    flower1.position.set(-1.25, 1, 0);
    scene.add(flower1);

    // Flower 2
    flower2 = clone(flower1);
    flower2.position.set(-2.25, 0.5, -0.35);
    flower2.rotation.z = -0.55;
    scene.add(flower2);

    // Flower 3
    flower3 = clone(flower1);
    flower3.position.set(6, 1, 0);
    flower3.rotation.z = 0.85;
    scene.add(flower3);

    // Flower 4
    flower4 = clone(flower1);
    flower4.position.set(6.25, 1.5, -0.5);
    scene.add(flower4);

    flowers = [flower1, flower2, flower3, flower4];

    flowers.forEach((flower, index) => {
      const flowerMixer = new THREE.AnimationMixer(flower);
      const flowerAction = flowerMixer.clipAction(gltf.animations[0]);
      flowerAction.setLoop(THREE.LoopOnce);
      flowerAction.clampWhenFinished = true;
      flowerAction.timeScale = 0;
      flowerAction.play();

      flowerMixers.push(flowerMixer);
    });

    activateScroll();
  }
);

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

const ambientLight = new THREE.AmbientLight("white", 1);
scene.add(ambientLight);

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.set(2, 2, 2);
scene.add(camera);

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  alpha: true,
  antialias: true,
  powerPreference: "high-performance",
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Animate
 */

const activateScroll = () => {
  flowers.forEach((flower, index) => {
    const flowerMixer = flowerMixers[index];
    const flowerAction = flowerMixer._actions[0];

    let tlScroll = gsap.timeline({
      // ... (scroll trigger setup)
      scrollTrigger: {
        trigger: section,
        start: "top 25%",
        end: "bottom 15%",
        scrub: 1,
        markers: false,
      },
    });

    const duration = flowerAction._clip.duration * (index + 1); // Different duration for each flower

    tlScroll.to(
      {},
      {
        duration: duration,
        onUpdate: () => {
          if (flowerAction) {
            flowerAction.time =
              tlScroll.progress() * flowerAction._clip.duration;
          }
        },
      }
    );
  });
};

const clock = new THREE.Clock();
let previousTime = 0;

const tick = () => {
  const elapsedTime = clock.getElapsedTime();
  const deltaTime = elapsedTime - previousTime;
  previousTime = elapsedTime;

  // Model animation
  /*
  if (mixer) {
   mixer.update(deltaTime);
  }
   */
  if (flowerMixers) {
    flowerMixers.forEach((mixer) => {
      mixer.update(deltaTime);
    });
  }
  if (flower3) {
    // flower3.rotation.z += deltaTime * 0.1;
  }

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
